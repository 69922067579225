import React, { useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import PropTypes from "prop-types";

const Layout = (props) => {
  const { title, children, location } = props;
  const [toggleNav, setToggleNav] = useState(false);
  const handleClick = () => setToggleNav(!toggleNav);
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <Header {...{ title, handleClick, location }} />
      <main id='site-main' className='site-main'>
        <div id='swup' className='transition-fade'>
          {children}
        </div>
      </main>
      <Footer title={title} isOpen={toggleNav} />
    </div>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
};

export default Layout;
