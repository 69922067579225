import React from "react";
import { Button, Text } from "@chakra-ui/react";

const DefaultButton = ({ title, onClick, backgroundColor, disabled, type }) => {
  return (
    <Button
      Buttonsize='md'
      height='42px'
      colorScheme='blue'
      variant='solid'
      bg='btBlue'
      borderRadius='5px'
      boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
      fontSize='20px'
      onClick={() => (onClick ? onClick() : () => {})}
      disabled={disabled}
      type={type}
    >
      {title}
    </Button>
  );
};

export default DefaultButton;
