import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

function Seo({ seoBundle }) {
  const metaData = {
    defaultTitle: "American Storage",
    defaultTitleTemplate: "%s | Portable Storage Units",
    defaultDescription: ``, //TODO
    siteUrl: "https://americanstorage.llc", // No trailing slash allowed!
    defaultImage: "/images/American_Storage_Logo.png", // Path to your image you placed in the 'static' folder  //TODO
    lang: "en", // e.g: en, es, fr, jp
    // socialLinks: {
    //   facebook: `https://www.facebook.com/`,
    //   twitter: `https://www.twitter.com/`,
    // },
    contact: {
      phone: `208-539-3383`,
      email: `ruthannebeers@gmail.com`,
    },
    address: {
      city: `Gooding`,
      region: `Idaho`,
      country: `United States`,
      zipCode: `83330`,
    },
    legalName: `American Storage`,
    foundingDate: `01/01/2007`,
  };
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const { site } = data;
        const metaDescription = seoBundle.subtitle || seoBundle.description;
        const titleText = seoBundle.title
          ? `${seoBundle.title} | ${
              seoBundle.subtitle || site?.siteMetadata?.title
            }`
          : site?.siteMetadata?.title;

        const seo = {
          title: seoBundle.title || metaData.defaultTitle,
          titleTemplate: seoBundle.subtitle
            ? `%s | ${seoBundle.subtitle}`
            : metaData.defaultTitleTemplate,
          description: metaDescription,
          image: seoBundle?.image?.url,
          url: `${metaData.siteUrl}/${seoBundle.slug}`,
        };

        const structuredDataOrganization = `{
          "@context": "http://schema.org",
          "@graph":[
            {
            "@type":"Organization",
            "@id":"https://americanstorage.llc/#organization",
          	"name":"American Storage | Portable Storage Units",
            "url":"${metaData.siteUrl}",
          	"logo":{
                "@type":"ImageObject",
                "@id":"https://americanstorage.llc/#logo",
          	    "url":"${metaData.defaultImage}", 
          	    "width":400,
                "height":400,
                "caption":"American Storage"
              },
            "image":{
                "@id":"https://americanstorage.llc/#logo"
              },
            "legalName": "${metaData.legalName}",
          	"foundingDate": "${metaData.foundingDate}",
          	"contactPoint": [
                  {
                  "@type": "ContactPoint",
                  "email": "${metaData.contact.email}",
                  "telephone": "${metaData.contact.phone}",
                  "contactType": "customer service"
          	   }
              ],
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "${metaData.address.city}",
                "addressRegion": "${metaData.address.region}",
                "addressCountry": "${metaData.address.country}",
                "postalCode": "${metaData.address.zipCode}"
              }
            },
            {
              "@type":"WebSite",
              "@id":"https://americanstorage.llc/#website",
              "url":"${metaData.siteUrl}",
              "name":"${metaData.defaultTitle}",
              "publisher":{
                "@id":"https://americanstorage.llc/#organization"
              }
            },
            {
              "@type":"WebPage",
              "@id":"https://americanstorage.llc/#webpage",
          	  "url":"${metaData.siteUrl}/${seoBundle.slug}",
              "inLanguage":"en-US",
          	  "name":"${titleText}",
          	  "isPartOf":{
                "@id":"https://americanstorage.llc/#website"
              },
          	  "about":{
                "@id":"https://americanstorage.llc/#organization"
              },
          	  "description":"${seo.description}"
            }
          ]
        }`;

        return (
          <Helmet
            titleTemplate={seo.titleTemplate}
            defaultTitle={metaData.defaultTitle}
          >
            <title>{seo.title}</title>
            <meta charset='UTF-8' />
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1'
            />
            <meta name='description' content={seo.description} />
            <meta name='image' content={seo.image} />
            {seo.url && <meta property='og:url' content={seo.url} />}
            <meta property='og:type' content='website' />
            <meta property='og:site_name' content={metaData.defaultTitle} />
            {seo.title && <meta property='og:title' content={seo.title} />}
            {seo.description && (
              <meta property='og:description' content={seo.description} />
            )}
            {seo.image && <meta property='og:image' content={seo.image} />}
            <meta property='og:locale' content='en_US' />

            {/* You can get this id when you create an app id on Facebook of your Facebook page */}
            {/* <meta property="fb:app_id" content={social.facebook} /> */}

            {/* These tags work for Twitter & Slack */}
            <meta name='twitter:card' content='summary_large_image' />
            {seo.title && <meta name='twitter:title' content={seo.title} />}
            {seo.description && (
              <meta name='twitter:description' content={seo.description} />
            )}
            {seo.image && <meta name='twitter:image' content={seo.image} />}

            {seoBundle.keywords && (
              <meta
                name='keywords'
                content={JSON.parse(seoBundle.keywords).meta.join(`, `)}
              />
            )}

            {/* Structured data */}
            <script type='application/ld+json'>
              {structuredDataOrganization}
            </script>
            <link rel='canonical' href={seo.url} />

            {/* Default language and direction */}
            <html lang='en' dir='ltr' />
          </Helmet>
        );
      }}
    />
  );
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

export default Seo;
