import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { Facebook, Twitter } from "../Icons";
import { Box } from "@chakra-ui/react";
import AmericanStorageLogo from "../../images/svg/American_Storage_Logo";

const Header = (props) => {
  const { handleClick, location } = props;
  return (
    <header className='site-head'>
      <div className='site-head-container'>
        <a className='nav-burger' href={`#`} onClick={() => handleClick()}>
          <div
            className='hamburger hamburger--collapse'
            aria-label='Menu'
            role='button'
            aria-controls='navigation'
          >
            <div className='hamburger-box'>
              <div className='hamburger-inner' />
            </div>
          </div>
        </a>
        <div className='site-head-center'>
          <Link className='site-head-logo' to={`/`}>
            <Box w={["50px", "100px"]}>
              <AmericanStorageLogo />
            </Box>
          </Link>
        </div>
        <nav id='swup' className='site-head-left'>
          <ul className='nav' role='menu'>
            <li
              className={`nav-home ${
                location.pathname === "/" ? "nav-current" : ""
              }`}
              role='menuitem'
            >
              <Link to={`/`}>Home</Link>
            </li>
            <li
              className={`nav-listen ${
                location.pathname === "/pricing-page" ? "nav-current" : ""
              }`}
              role='menuitem'
            >
              <Link to={`/pricing-page`}>Storage Sizes</Link>
            </li>
            {/* <li
              className={`nav-listen ${
                location.pathname === "/order" ? "nav-current" : ""
              }`}
              role='menuitem'
            >
              <Link to={`/order`}>Order</Link>
            </li> */}
            <li
              className={`nav-about ${
                location.pathname === "/about" ? "nav-current" : ""
              }`}
              role='menuitem'
            >
              <Link to={`/about`}>About</Link>
            </li>
            <li
              className={`nav-contact ${
                location.pathname === "/contact" ? "nav-current" : ""
              }`}
              role='menuitem'
            >
              <Link to={`/contact`}>Contact Us</Link>
            </li>
            {/* <li>
              {" "}
              <Link to={`tel:2085393383`}>
                <StaticImage
                  src='../images/phone.png'
                  alt='chart of features'
                  placeholder='blurred'
                  layout='constrained'
                  width={30}
                />
                (208) 539-3383
              </Link>
            </li> */}
          </ul>
        </nav>
        <div className='site-head-right'>
          <div className='social-links'>
            <Link to={`tel:2085393383`}>
              <StaticImage
                src='../images/phone.png'
                alt='chart of features'
                placeholder='blurred'
                layout='constrained'
                width={30}
              />
              (208) 539-3383
            </Link>
            {/* <div className='facebook'>
              <Link
                href='https://www.facebook.com/'
                title='Facebook'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Facebook width={27} height={27} />
              </Link>
            </div>
            <div className='twitter'>
              <Link
                href='https://twitter.com/'
                title='Twitter'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Twitter width={30} height={30} />
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  location: PropTypes.object,
};

export default Header;
