import React from "react";
import { Container } from "@chakra-ui/react";

const Card = ({ children }) => {
  return (
    <Container
      bg='#fff'
      boxShadow='1px 4px 20px 15px rgba(0, 0, 0, 0.05)'
      borderRadius='25px'
      justify='center'
      align='center'
      px='40px'
      py='40px'
    >
      {children}
    </Container>
  );
};

export default Card;
