// component class = f
import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { Facebook, Twitter } from "../Icons";
import { customProperties } from "../../lib/exports";
import AmericanStorageLogo from "../../images/svg/American_Storage_Logo";
import { Box, Flex } from "@chakra-ui/react";

const Footer = (props) => {
  const { title, color, isOpen } = props;
  const fill = color === "black" ? "#ccc" : customProperties["--color-base"];
  return (
    <footer className={`site-foot ${color}`}>
      {isOpen && (
        <div className='logo open'>
          {/* <Logo height={40} fill={customProperties["--color-primary"]} /> */}
          <div
            style={{ color: customProperties["--color-primary"] }}
            className='register'
          >
            {" "}
            &reg;
          </div>
        </div>
      )}
      {!isOpen && (
        <>
          <Flex flexDirection='row' align='center' mb='10px'>
            <Box w='150px' mr='5px'>
              <AmericanStorageLogo />
            </Box>
            <Box>&reg;</Box>
          </Flex>
          <div className='social-links'>
            <Link to={`tel:2085393383`}>
              <StaticImage
                src='../images/phone.png'
                alt='chart of features'
                placeholder='blurred'
                layout='constrained'
                width={30}
              />
              (208) 539-3383
            </Link>
            {/* <div className='facebook'>
              <Link
                href='https://www.facebook.com/'
                title='Facebook'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Facebook width={27} height={27} />
              </Link>
            </div>
            <div className='twitter'>
              <Link
                href='https://twitter.com/'
                title='Twitter'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Twitter width={30} height={30} />
              </Link>
            </div> */}
          </div>
          <div className='f-links'>
            <span>
              <Link href='/privacy-policy' title='Privacy Policy'>
                Privacy Policy
              </Link>{" "}
              |{" "}
              <Link href='/contact' title='Contact Us'>
                Contact Us
              </Link>{" "}
              {/* |{" "}
              <Link href='/faqs' title='FAQ'>
                FAQ
              </Link> */}
            </span>
          </div>
          <div className='f-site-info'>
            &copy; {new Date().getFullYear()}{" "}
            <Link to={`/`}>American Storage</Link> | Built by Fierce Island&reg;
          </div>
        </>
      )}
    </footer>
  );
};

Footer.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["white", "black"]),
  isOpen: PropTypes.bool,
};

Footer.defaultProps = {
  color: "white",
  isOpen: false,
};

export default Footer;
